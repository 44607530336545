html {
    font-family: system-ui, sans-serif;
    font-size: 1.25rem;
  }
  
  body {
    margin: 0;
    /* padding: 0 1.5rem; */
  }
  
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  