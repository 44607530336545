#scrollableDivv::-webkit-scrollbar{
    /* display: none;
    overflow-x: 'hidden'; */
    width: 10px;
    /* height: 174px; */
    /* background: gray; */
    border-radius: 10px;
    background: rgba(195, 196, 197, 0.912);
 
  }
  #scrollableDivv::-webkit-scrollbar-track{
    /* display: none;
    overflow-x: 'hidden'; */
    /* width: 20px; */
    /* background: gray; */
    border-radius: 10px;
    /* background: gray; */
  }
  #scrollableDivv::-webkit-scrollbar-thumb{
    /* display: none;
    overflow-x: 'hidden'; */
    /* width: 20px; */
    /* background: gray; */
    border-radius: 10px;
    /* background: hsla(33, 96%, 44%, 1); */
    height: 174px;
    /* background: hsla(220, 17%, 93%, 1); */
    background: rgba(195, 196, 197, 0.912);
  }
  /* html {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;
  }
  body::-webkit-scrollbar {
    width: 11px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
  
  
  html {
    background: lightgrey;
    overflow: hidden;
  }
  body {
    height: 100vh;
    background: whitesmoke;
    overflow-y: auto;
    max-width: 310px;
    margin: 0 auto;
    padding: 2rem;
    font: 100%/1.4 serif;
    border: 1px solid rgba(0, 0, 0, 0.25)
  } */
  /* body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  } */
#scrollableDivv{
  /* background-color: aqua; */
}